import {io} from 'socket.io-client';
import {createContext, useEffect, useState} from "react";
import {useAuth} from "@clerk/clerk-react";
import {Socket} from "socket.io-client/build/esm/socket";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_API_URL;

interface SocketIoContextType {
  socket?: Socket
  connected: boolean
}

export const SocketIoContext = createContext<SocketIoContextType>({
  connected: false
});


// @ts-ignore
export default function SocketIoProvider({children}) {
  const {getToken, isLoaded: isAuthReady, isSignedIn, sessionId, userId} = useAuth()

  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (!isAuthReady) {
      return
    }

    const newSocket: Socket = io(URL, {
      'path': '/rt'
    });

    newSocket.on('connect', () => setIsConnected(true));
    newSocket.on('disconnect', () => setIsConnected(false));
    newSocket.on('auth', async (callback) => {
      const token = await getToken()
      callback(token)
    });
    newSocket.on('notification', async (data) => {
      console.log("notification", data)
    });

    // @ts-ignore
    setSocket(newSocket)


    return () => {
      newSocket.off('connect');
      newSocket.off('auth');
      newSocket.off('notification');
      newSocket.off('disconnect');
      newSocket.close()
    };
  }, [isAuthReady]);

  return (
    <SocketIoContext.Provider value={{
      socket,
      connected: isConnected
    }}>
      {children}
    </SocketIoContext.Provider>
  );
}